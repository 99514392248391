<template>
  <main class="text-left">
    <section class="d-flex align-items-center" id="hero">
      <div class="container">
        <div class="row">
          <div class="col-md-10 col-lg-5">
            <h1>¿ESTAS LISTO PARA UNIRTE?</h1>
            <p>
              Especialízate en la formación en el ámbito de la informática y
              Telecomunicaciones, logrando obtener certificación internacional
              de gran demanda para nuevas y actuales tecnologías.
            </p>
            <router-link class="nav-link" to="/training"
              ><button class="btn" type="button">
                Más Información
              </button></router-link
            >
          </div>
        </div>
      </div>
    </section>
    <section id="services">
      <div class="container">
        <div class="row">
          <div class="col text-center">
            <h1>¿QUÉ OFRECEMOS?</h1>
          </div>
        </div>
        <div class="row services-card">
          <div class="col-md-6 d-flex">
            <i class="fas fa-shield-alt d-flex align-items-center"></i>
            <div class="card-service">
              <h1>Garantía</h1>
              <p>Enseñanza garantizada, con equipos en sitio.</p>
            </div>
          </div>
          <div class="col-md-6 d-flex">
            <i class="fas fa-chalkboard d-flex align-items-center"></i>
            <div class="card-service">
              <h1>Clases Regulares</h1>
              <p>
                Brindamos clases regulares y aceleradas, que le permiten lograr
                sus objetivos.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex">
            <i class="fas fa-certificate d-flex align-items-center"></i>
            <div class="card-service">
              <h1>Instructores&nbsp;<br />Certificados</h1>
              <p>
                Que exigen de igual manera a nuestros alumnos para una
                comprensión al 100%.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex">
            <i class="fas fa-school d-flex align-items-center"></i>
            <div class="card-service">
              <h1>Aulas Equipadas</h1>
              <p>
                Que permiten poner en práctica lo aprendido, interactuando con
                los dispositivos.
              </p>
            </div>
          </div>
          <div class="col-md-6 d-flex">
            <i class="far fa-window-restore d-flex align-items-center"></i>
            <div class="card-service">
              <h1>Lecciones<br />Creativas</h1>
              <p>
                Explicaciones creativas, fáciles de comprender y retener a lo
                largo del curso.
              </p>
            </div>
          </div>
        </div>
      </div>
    </section>
    <section id="features">
      <div class="container news">
        <div class="row">
          <div class="col">
            <h2 class="text-center">NOVEDADES</h2>
          </div>
        </div>
        <div class="row">
          <div class="col banner-pearson">
            <div class="custom-banner">
              <h1>
                Centro Autorizado Pearson Vue
              </h1>
              <router-link to="/training"
                ><button class="btn" type="button">
                  CONOCER MÁS
                </button></router-link
              >
            </div>
          </div>
          <div class="col d-flex justify-content-center">
            <iframe
              src="https://www.facebook.com/plugins/page.php?href=https%3A%2F%2Fwww.facebook.com%2Fcertifiednet%2F&tabs=timeline&width=1200&height=500&small_header=true&adapt_container_width=false&hide_cover=true&show_facepile=true&appId"
              width="500"
              height="500"
              style=""
              scrolling="no"
              frameborder="0"
              allowfullscreen="true"
              allow="autoplay; clipboard-write; encrypted-media; picture-in-picture; web-share"
            ></iframe>
          </div>
        </div>
      </div>
      <div class="container courses">
        <div class="row">
          <div class="col">
            <h2 id="courses" class="text-center">CURSOS DESTACADOS</h2>
          </div>
        </div>
        <div class="row">
          <div
            v-for="course in courses"
            :key="course"
            class="col-md-6 col-lg-4 course-column"
          >
            <div class="course-card">
              <div class="course-card-image">
                <img
                  class="img-fluid"
                  :src="require('../assets/img/img/' + course.image)"
                />
              </div>
              <h5>{{ course.category }}</h5>
              <h3>{{ course.title }}</h3>
              <p>
                {{ course.description }}
              </p>
              <a href="https://wa.link/f9l6sk" target="_blank">
                <button class="btn" type="button">Ver Más</button></a
              >
            </div>
          </div>
        </div>
      </div>
    </section>
  </main>
</template>

<script>
export default {
  name: "Home",
  data() {
    return {
      courses: [
        {
          id: 1,
          image: "CCNA-LOGO-lite.png",
          category: "Switching",
          title: "CCNA 200 - 30",
          description:
            "La certificación Cisco CCNA es una de las certificaciones mas reconocidas para un Ingeniero",
        },
        {
          id: 2,
          image: "ethical-hacking-lite.png",
          category: "Hacking",
          title: "ETHICAL HACKER V10",
          description:
            "Se profundizan los conceptos de seguridad informática y ataques cibernéticos.",
        },
        {
          id: 3,
          image: "microsoft-mcsa.png",
          category: "Servidores",
          title: "CCNA 200 - 301",
          description:
            "La certificación Cisco CCNA es una de las certificaciones mas reconocidas para un Ingeniero",
        },
      ],
    };
  },
};
</script>

<style scoped>
#hero {
  background: url("../assets/img/img/hero-section-custom.png") center / cover;
  height: 100vh;
  color: #e5e5e5;
  font-size: 1.2rem;
}

#hero h1 {
  font-size: 4rem;
  font-weight: 700;
}

#hero button {
  background-color: #201b4a;
  color: #e5e5e5;
  font-size: 1.2rem;
  width: 50%;
  border-radius: 10px;
}

#hero button:hover {
  color: #201b4a;
  background-color: #e5e5e5;
  border-style: solid;
  border-color: #201b4a;
}

.card-service {
  font-size: 1.2rem;
  display: flex;
  flex-direction: column;
  justify-content: center;
}

.row.services-card {
  margin-top: 20px;
}

#services .container {
  background-color: white;
  padding: 30px 10px;
  box-shadow: 3px 3px 13px 6px rgba(174, 174, 174, 0.2);
}

#services .col-md-6 {
  margin: 10px 0px;
}

.btn.btn-pearson {
  width: 100%;
  background-color: #201b4a;
  color: rgb(255, 255, 255);
  border-radius: 10px;
  border-style: solid;
  border-color: white;
}

.container.banner {
  background-color: #201b4a;
  color: white;
  padding: 20px 30px;
  box-shadow: 1px 2px 10px 4px rgba(137, 137, 137, 0.43);
  margin-top: 20px;
}

.btn.btn-pearson:hover {
  background-color: white;
  border-color: #201b4a;
  color: #201b4a;
}

.banner-items {
  margin: 5px 0px;
}

.container.courses {
  color: #201b4a;
  margin-top: 30px;
  margin-bottom: 30px;
}

.course-card h5 {
  color: #878383;
}

.course-card p {
  color: #696969;
  font-size: 1.1rem;
}

.course-card .btn {
  background-color: #201b4a;
  color: white;
  width: 50%;
  border-radius: 10px;
  font-size: 1.1rem;
}

.course-card .btn:hover {
  border-style: solid;
  border-color: #201b4a;
  color: #201b4a;
  background-color: white;
}

.course-card {
  min-height: 35rem;
  height: 100%;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  padding: 10px;
  box-shadow: 3px 3px 12px 2px rgba(134, 134, 142, 0.2);
}

.course-column {
  margin: 5px 0px;
}

#services {
  background: #eff3f9;
  padding: 20px 0px;
  color: #201b4a;
}

#services i {
  font-size: 4.5rem;
  margin: 0px 15px;
}

.banner-items h3 {
  margin: 0;
}

.facebookPosts {
  background-color: #eff3f9;
}

.news {
  margin-top: 20px;
  margin-bottom: 20px;
  color: #201b4a;
}

.button-more {
  color: white;
}

.col.banner-pearson {
  color: white;
  padding: 0px 30px;
}

.col.banner-pearson button {
  color: white;
  background: #201b4a;
  border-radius: 10px;
}

.col.banner-pearson button:hover {
  color: #201b4a;
  background-color: white;
  border-color: #201b4a;
}

.custom-banner {
  background: url("../assets/img/img/CertifiedNetPromoWeb.jpg");

  height: 100%;
  padding: 10px;
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: flex-start;
  border-radius: 10px;
}

.custom-banner h1 {
  visibility: hidden;
}

@media (max-width: 991px) {
  .custom-banner {
    background: #201b4a;
  }

  .custom-banner h1 {
    visibility: visible;
  }

  .col.banner-pearson button {
    border-style: solid;
    border-color: white;
    color: white;
    border-radius: 10px;
  }

  .col.banner-pearson button:hover {
    color: #201b4a;
    background-color: white;
    border-color: #201b4a;
  }
}
</style>
