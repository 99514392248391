<template>
  <main class="text-left">
    <div class="container training">
      <div class="row training-title">
        <div class="col">
          <h2 class="text-center">
            Entrenamiento en Tecnologías de la Información TIC´s
          </h2>
        </div>
      </div>
      <div
        v-for="course in courses"
        :key="course.id"
        class="row training-service"
      >
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <img
            class="img-fluid"
            :src="require('../assets/img/img/' + imageToShow(course.imgDir))"
          />
        </div>
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <h2>{{ course.title }}</h2>
          <p>
            {{ course.description }}
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
import conf from "../../configdata";
export default {
  name: "Training",
  data() {
    return {
      courses: [],
    };
  },
  created() {
    this.courses = conf.data;
  },
  methods: {
    imageToShow(name) {
      if (!name) {
        return "CertifiedNet.png";
      }
      return name;
    },
  },
};
</script>

<style scoped>
.training-title {
  font-weight: 600;
}

.container.training {
  color: #201b4a;
}

.btn {
  background-color: #201b4a;
  color: white;
  border-radius: 10px;
  transition: all 0.3s;
}

.btn:hover {
  border-style: solid;
  border-color: #201b4a;
  color: #201b4a;
  background-color: white;
}

.row.training-service {
  margin: 50px 0px;
  padding: 10px 0px;
  min-height: 280px;
}

.training-service:nth-child(odd) {
  display: flex;
  flex-direction: row-reverse;
}

.row.training-title {
  margin-top: 30px;
}

.training-title h2 {
  font-weight: 600;
}

@media (max-width: 576px) {
  .row.training-service {
    box-shadow: 2px 2px 9px 2px rgba(169, 169, 169, 0.35);
  }
}

body {
  font-family: Asap, sans-serif;
}
</style>
