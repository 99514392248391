import { createRouter, createWebHistory } from "vue-router";
import Home from "../views/Home.vue";
import Training from "../views/Training.vue";
import Contact from "../views/Contact.vue";
import PearsonVue from "../views/PearsonVue.vue";
import NotFound from "../views/NotFound.vue";

const routes = [
  {
    path: "/",
    redirect: "/home",
  },
  {
    path: "/home",
    name: "Home",
    component: Home,
    meta: {
      title: "Certified Net - Inicio",
    },
  },
  {
    path: "/training",
    name: "Training",
    component: Training,
    meta: {
      title: "Certified Net - Training",
    },
  },
  {
    path: "/contact",
    name: "Contact",
    component: Contact,
    meta: {
      title: "Certified Net - Contact",
    },
  },
  {
    path: "/pearson-vue",
    name: "PearsonVue",
    component: PearsonVue,
    meta: {
      title: "Pearson Vue",
    },
  },
  {
    path: "/about",
    name: "About",
    // route level code-splitting
    // this generates a separate chunk (about.[hash].js) for this route
    // which is lazy-loaded when the route is visited.
    component: () =>
      import(/* webpackChunkName: "about" */ "../views/About.vue"),
    meta: {
      title: "Certified Net - Nosotros",
    },
  },
  {
    path: "/:notFound(.*)",
    name: "NotFound",
    component: NotFound,
    meta: {
      title: "Certified Net - Pagina no encontrada",
    },
  },
];

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes,
  scrollBehavior() {
    document.documentElement.scrollTop = 0;
  },
});

export default router;
