<template>
  <footer class="footer-dark text-left">
    <div class="container">
      <div class="row">
        <div class="col-lg-3 column-footer">
          <h4>Dirección</h4>
          <p>
            Calle A. Ascarrunz Nro. 2506, esquina Calle Lisimaco Gutierrez Zona
            Sopocachi
          </p>
          <p>Tel.: (+591) 2410150 int 1022</p>
          <p>Cel.: (+591) 76588219</p>
          <p>info@certifiednet.com.bo</p>
        </div>
        <div class="col-lg-3 column-footer">
          <h4>Training</h4>
          <ul>
            <li>
              <a href="http://wa.link/kxal3g" target="_blank"
                >Contactos Pearson Vue</a
              >
            </li>
            <li>
              <a href="https://t.me/certifiednet" target="_blank"
                >Atención al Cliente</a
              >
            </li>
          </ul>
        </div>
        <div class="col-lg-3 column-footer">
          <h4>Accesibilidad</h4>
          <ul>
            <li><router-link to="/home">Inicio</router-link></li>
            <li><router-link to="/training">Training </router-link></li>
            <li><router-link to="/about">Nosotros </router-link></li>
            <li><router-link to="/contact">Contacto </router-link></li>
          </ul>
        </div>
        <div class="col-lg-3 column-footer">
          <h4>Contactos</h4>
          <ul>
            <li>
              <i class="fab fa-facebook"></i
              ><a href="https://www.facebook.com/certifiednet" target="_blank"
                >&nbsp;Facebook</a
              >
            </li>
            <li>
              <i class="fab fa-instagram"></i
              ><a href="https://www.instagram.com/certifiednet/" target="_blank"
                >&nbsp;Instagram</a
              >
            </li>
            <li>
              <i class="fab fa-telegram"></i
              ><a href="https://t.me/certifiednet" target="_blank"
                >&nbsp;Telegram</a
              >
            </li>
            <li>
              <i class="fab fa-whatsapp"></i
              ><a href="http://wa.link/kxal3g" target="_blank"
                >&nbsp;Whatsapp</a
              >
            </li>
          </ul>
        </div>
      </div>
      <p class="copyright">
        <br />Copyrights ©️ 2021 - CertifiedNet Enseñanza Tecnológica LTDA by
        SAML<br />
      </p>
    </div>
  </footer>
</template>

<style scoped>
.footer-dark {
  padding: 50px 0;
  color: #b7b7b7;
  background-color: #282d32;
}

.footer-dark h3 {
  margin-top: 0;
  margin-bottom: 12px;
  font-size: 16px;
}

.footer-dark ul {
  padding: 0;
  list-style: none;
  line-height: 1.6;
  font-size: 14px;
  margin-bottom: 0;
}

.footer-dark ul a {
  color: inherit;
  text-decoration: none;
  opacity: 0.6;
}

.footer-dark ul a:hover {
  opacity: 0.8;
}

@media (max-width: 767px) {
  .footer-dark .item:not(.social) {
    text-align: center;
    padding-bottom: 20px;
  }
}

.footer-dark .item.text {
  margin-bottom: 36px;
}

@media (max-width: 767px) {
  .footer-dark .item.text {
    margin-bottom: 0;
  }
}

.footer-dark .item.text p {
  opacity: 0.6;
  margin-bottom: 0;
}

.footer-dark .item.social {
  text-align: center;
}

@media (max-width: 991px) {
  .footer-dark .item.social {
    text-align: center;
    margin-top: 20px;
  }
}

.footer-dark .item.social > a {
  font-size: 20px;
  width: 36px;
  height: 36px;
  line-height: 36px;
  display: inline-block;
  text-align: center;
  border-radius: 50%;
  box-shadow: 0 0 0 1px rgba(255, 255, 255, 0.4);
  margin: 0 8px;
  color: #fff;
  opacity: 0.75;
}

.footer-dark .item.social > a:hover {
  opacity: 0.9;
}

.footer-dark .copyright {
  text-align: center;
  padding-top: 24px;
  opacity: 0.3;
  font-size: 13px;
  margin-bottom: 0;
}

li {
  font-size: 1rem;
}

.column-footer {
  margin: 10px 0px;
}
</style>
