<template>
  <main style="background: #eff3f9">
    <div class="container">
      <div class="row">
        <div class="col not-found-column">
          <div class="banner-not-found">
            <h1>Página no encontrada</h1>
            <p>La página que encontraste no existe</p>
            <router-link to="/home"
              ><button class="btn" type="button">
                Regresar a la Página Principal
              </button></router-link
            >
          </div>
        </div>
      </div>
    </div>
  </main>
</template>

<style scoped>
.col.not-found-column {
  background: url("../assets/img/img/not-found-image.jpg") center / cover;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 80vh;
  margin: 30px 0px;
  border-radius: 5px;
}

.banner-not-found {
  color: white;
  background: rgba(32, 27, 74, 0.61);
  padding: 3%;
  border-radius: 15px;
}

.banner-not-found button {
  background-color: #f7f6ff;
  color: #1f2154;
  border-radius: 10px;
  font-weight: 500;
}

.banner-not-found button:hover {
  background-color: #1f2154;
  color: #f7f6ff;
  border-style: solid;
  border-color: #f7f6ff;
}
</style>
