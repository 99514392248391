<template>
  <div class="error">
    <ul>
      <li v-for="error in errors" :key="error">{{ error }}</li>
    </ul>
  </div>
</template>

<script>
export default {
  props: ["errors"],
};
</script>

<style scoped>
.error {
  margin-top: 1rem;
  border-radius: 0.7rem;
  border-style: solid;
  border-width: 1px;
  border-color: #ff5454;
  color: #ff5454;
  padding: 1rem;
}

.error li {
  margin: 0;
}
</style>
