<template>
  <main id="contact" class="text-left">
    <div class="container">
      <div class="row">
        <div class="col">
          <h2 class="text-center contact-title">CONTACTO</h2>
        </div>
      </div>
      <div class="row">
        <div class="col">
          <iframe
            src="https://www.google.com/maps/embed?pb=!1m18!1m12!1m3!1d3216.6651985250705!2d-68.12929323823974!3d-16.511898391726504!2m3!1f0!2f0!3f0!3m2!1i1024!2i768!4f13.1!3m3!1m2!1s0x915f215ba86ed39f%3A0x37262f748820cd58!2sGroupcisc%2C%20Centro%20Empresarial%20SRL!5e0!3m2!1ses!2sbo!4v1614695970086!5m2!1ses!2sbo"
            width="100%"
            height="450"
            style="border: 0"
            allowfullscreen=""
            loading="lazy"
          ></iframe>
        </div>
      </div>
      <div class="row">
        <div class="col text-center info-contact">
          <h5>DIRECCIÓN</h5>
          <p>
            Calle A. Ascarrunz Nro. 2506, esquina Calle Lisimaco Gutierrez Zona
            Sopocachi<br />La Paz - Bolivia
          </p>
          <h5>NÚMEROS</h5>
          <p>
            Teléfono: (+591) 2410150 int 1022<br />
            Telegram: (+591) 76588219
            <a href="https://t.me/certifiednet" target="_blank"
              ><i class="fab fa-telegram"></i
            ></a>
            <a href="http://wa.link/kxal3g" target="_blank">
              <i class="fab fa-whatsapp"></i
            ></a>
          </p>
          <h5>HORARIOS</h5>
          <p>
            Lunes a Viernes<br />08:00 - 12:30 Mañana<br />14:30 - 18:00 Tarde
          </p>
        </div>
      </div>
    </div>
  </main>
</template>

<script>
export default {
  name: "Contact",
};
</script>

<style scoped>
.img-fluid .contact {
  padding: 30px;
  width: 60%;
}

.img-fluid.contact {
  width: 50%;
}

.info-contact {
  font-size: 1.1rem;
}

.contact-title {
  font-weight: 600;
}
</style>
