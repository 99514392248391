const data = [
  {
    id: 1,
    title: "EC-Council",
    description:
      " Somos Centro Autorizado EC-Council el cual nos permite certificar a nuestros estudiantes en varias habilidades de comercio electrónico y seguridad de la información Tics, siendo el único centro en toda Bolivia especializado en Hacking Ético, Seguridad Informática e Informática Forense.",
    imgDir: "ec-council.png",
  },
  {
    id: 2,
    title: "Formamos parte del LPI",
    description:
      "Al ser parte de Linux Professional Institute (LPI), tenemos como objetivo principal el promover y certificar capacidades esenciales en LINUX y código abierto a través de la creación de exámenes altamente comprensibles y de gran calidad.",
    imgDir: "linux-professional-institute.png",
  },
];

export default { data };
