<template>
  <div class="principal">
    <div class="container">
      <div class="row text-center justify-content-center  ">
        <h1>Capacitación Pearson Vue</h1>
      </div>
      <div class="row training-service">
        <div class="col-md-6 d-flex flex-column justify-content-center">
          <h2>Centro Autorizado Pearson VUE</h2>
          <p>
            Al ser Centro Autorizado Pearson VUE, usted puede realizar los
            exámenes que le permiten acceder a centenares de certificaciones
            internacionales y cualificaciones que le permitan conseguir un
            desarrollo profesional destacado.
          </p>
        </div>
        <div
          class="col-md-6 d-flex flex-column justify-content-center text-center"
        >
          <a href="http://wa.link/kxal3g" target="_blank">
            <img
              class="img-fluid"
              src="../assets/img/img/Pearson-vue-semilite.png"
            />
            <button class="btn" type="button">
              Consulta para mas información
            </button></a
          >
        </div>
      </div>
      <div class="row">
        <div class="column">
          <h1>Solicitud de Certificacion Pearson Vue</h1>
        </div>
      </div>
    </div>
    <div v-if="!formSuccess" class="border rouded shadow main">
      <form @submit.prevent="submitForm" id="pearsonForm">
        <h2>Datos Personales</h2>
        <p>
          La información contenida en el presente formulario será mantenida
          confidencial y sólo será utilizada por los organizadores
        </p>
        <p>(*) son campos obligatorios</p>
        <div>
          <label for="name">Nombre *</label>
          <input
            @input="testFunc(errorMessage.name)"
            class="form-control"
            type="text"
            v-model="name"
            id="name"
            required
          />
        </div>
        <div>
          <label for="lastName">Apellidos *</label>
          <input
            @input="testFunc(errorMessage.lastName)"
            class="form-control"
            type="text"
            v-model="lastName"
            id="lastName"
            required
          />
        </div>
        <div>
          <label for="email">Correo Electronico *</label>
          <input
            @input="testFunc(errorMessage.email)"
            class="form-control"
            id="email"
            type="email"
            v-model="email"
            required
          />
        </div>
        <div>
          <label for="birthDate">Fecha de Nacimiento</label>
          <input
            type="date"
            class="form-control"
            v-model="birthDate"
            id="birthDate"
          />
        </div>
        <div>
          <label for="direction">Dirección</label>
          <input
            class="form-control"
            type="text"
            v-model="direction"
            id="direction"
          />
        </div>
        <div>
          <label for="contact">Tel/Cel</label>
          <input
            type="tel"
            id="contact"
            class="form-control"
            v-model="contactNumber"
          />
        </div>
        <h2 class="secondary-heading">Datos Institucionales</h2>
        <div>
          <label for="haveWork">¿Actualmente Trabaja?</label>
          <div id="haveWork">
            <input type="radio" value="no" v-model="haveWork" />
            <label class="label-radio" for="no">No</label>

            <input type="radio" value="si" v-model="haveWork" />
            <label class="label-radio" for="si">Si</label>
          </div>
        </div>
        <div v-if="userHaveWork">
          <label for="stateDependency"
            >Dependencia de Estado en la cual trabaja (omite si no
            corresponde)</label
          >
          <input
            class="form-control"
            type="text"
            v-model="stateDependency"
            id="stateDependency"
          />
        </div>
        <div>
          <label for="testType">¿Qué examen deseas realizar? *</label>
          <input
            @input="testFunc(errorMessage.testType)"
            type="text"
            id="testType"
            class="form-control"
            v-model="testType"
            required
          />
        </div>
        <div>
          <label for="testWithUs">
            ¿Deseas adquirir con nosotros el examen?
          </label>
          <div id="testWithUs">
            <input type="radio" value="no" v-model="testWithUs" />
            <label class="label-radio" for="no">No</label>

            <input type="radio" value="si" v-model="testWithUs" />
            <label class="label-radio" for="si">Si</label>
          </div>
        </div>
        <div>
          <label for="havePearsonAccount">
            ¿Tienes una cuenta Pearson vue?
          </label>
          <div id="havePearsonAccount">
            <input type="radio" value="no" v-model="havePearsonAccount" />
            <label class="label-radio" for="no">No</label>

            <input type="radio" value="si" v-model="havePearsonAccount" />
            <label class="label-radio" for="si">Si</label>
          </div>
        </div>
        <div v-if="userHavePearsonAccount">
          <label for="pearsonAccount">
            ¿Cual es tu cuenta Pearson Vue?
          </label>
          <input
            type="text"
            id="pearsonAccount"
            class="form-control"
            v-model="pearsonAccount"
          />
        </div>
        <div>
          <label for="feedbackSocial">
            ¿Cómo se enteró de nuestro centro?
          </label>
          <input
            type="text"
            id="feedbackSocial"
            class="form-control"
            v-model="feedbackSocial"
          />
        </div>
        <button type="submit">
          Enviar
        </button>
      </form>
      <error-values v-if="showErrors" :errors="errors"></error-values>
    </div>
    <div v-else class="successSubmit shadow">
      <h2>Datos Enviados</h2>
      <p>Tus datos han sido Enviados Correctamente {{ result }}</p>
      <button @click="resetForm">Volver a solicitar</button>
    </div>
  </div>
</template>

<script>
import emailjs from "emailjs-com";
export default {
  computed: {
    showErrors() {
      return this.errors.length > 0;
    },
    userHaveWork() {
      return this.haveWork !== null && this.haveWork === "si";
    },
    userHavePearsonAccount() {
      return (
        this.havePearsonAccount !== null && this.havePearsonAccount === "si"
      );
    },
  },
  data() {
    return {
      name: "",
      lastName: "",
      email: "",
      birthDate: "",
      direction: "",
      contactNumber: "",
      stateDependency: "",
      haveWork: "",
      testType: "",
      testWithUs: "",
      havePearsonAccount: "",
      pearsonAccount: "",
      feedbackSocial: "",
      errors: [],
      hasFormErrors: false,
      result: null,
      isValidForm: false,
      errorMessage: {
        name: "Se requiere su nombre",
        lastName: "Se requiere sus apellidos",
        email: "Se requiere un correo electrónico",
        testType: "Se requiere el tipo de examen que desea realizar",
      },
      formSuccess: false,
    };
  },
  methods: {
    resetForm() {
      this.resetData();
      this.formSuccess = false;
    },
    testFunc(val) {
      if (this.errors.length > 0) {
        this.errors = this.errors.filter((error) => error !== val);
      }
    },
    submitForm() {
      if (!this.name.trim()) {
        this.errors.push(this.errorMessage.name);
      }

      if (!this.lastName.trim()) {
        this.errors.push(this.errorMessage.lastName);
      }

      if (!this.email.trim()) {
        this.errors.push(this.errorMessage.email);
      }

      if (!this.testType.trim()) {
        this.errors.push(this.errorMessage.testType);
      }

      console.log(
        this.errors.length > 0 ? this.errors : "there are not errors"
      );
      this.sendMail();
    },
    sendMail() {
      try {
        emailjs.send(
          "service_x93t2oa",
          "template_z1asi1q",
          {
            name: this.name,
            lastName: this.lastName,
            email: this.email,
            birthDate: this.birthDate,
            contactNumber: this.contactNumber,
            direction: this.direction,
            stateDependency: this.stateDependency,
            haveWork: this.haveWork,
            testType: this.testType,
            testWithUs: this.testWithUs,
            havePearsonAccount: this.havePearsonAccount,
            pearsonAccount: this.pearsonAccount,
            feedbackSocial: this.feedbackSocial,
          },
          "user_tLMcczYprrDnPLKHp5FdN"
        );
        this.resetData();
        this.result = this.name;
        this.formSuccess = true;
      } catch (error) {
        console.log(error);
      }
    },
    resetData() {
      this.name = "";
      this.lastName = "";
      this.email = "";
      this.birthDate = "";
      this.contactNumber = "";
      this.direction = "";
      this.stateDependency = "";
      this.haveWork = "";
      this.testType = "";
      this.testWithUs = "";
      this.havePearsonAccount = "";
      this.pearsonAccount = "";
      this.feedbackSocial = "";
    },
  },
};
</script>

<style scoped>
.principal {
  min-height: 80vh;
  height: auto;
  padding: 2rem 0;
}

h1 {
  font-weight: 600;
}

.main {
  max-width: 40rem;
  margin: auto;
  margin-top: 1rem;
  display: flex;
  flex-direction: column;
  border-style: none;
  padding: 2rem;
  border-radius: 0.8rem;
}

.main form {
  display: flex;
  flex-direction: column;
  margin: 1rem 0s;
}

.main textarea {
  margin-top: 1rem;
}

.main form button {
  margin-top: 1.5rem;
  padding: 0.5rem;
  border-radius: 0.5rem;
  border-style: none;
  color: white;
  background-color: #201b4a;
  transition: 0.1s;
}

.main form button:hover {
  color: #201b4a;
  background-color: white;
  border-style: solid;
  border-color: #201b4a;
  border-width: 1px;
}

form div {
  display: flex;
  flex-direction: row;
  align-items: center;
}

form div {
  margin-top: 1rem;
}

form div label {
  width: 7rem;
  margin-right: 0.5rem;
}

.label-radio {
  margin: 0 0 0 0.3rem;
}

.secondary-heading {
  margin-top: 1rem;
}

@media only screen and (max-widht: 40rem) {
  .main {
    margin: 1rem;
  }
}

.successSubmit {
  width: 40rem;
  padding: 2rem;
  margin: auto;
  border-radius: 0.8rem;
  margin-top: 2rem;
}

.successSubmit button {
  padding: 0.5rem;
  border-radius: 0.8rem;
  background-color: #201b4a;
  color: white;
  border-style: none;
  transition: 0.1s;
}

.successSubmit button:hover {
  color: #201b4a;
  background-color: white;
  border-style: solid;
  border-color: #201b4a;
  border-width: 1px;
}

.row.training-service {
  margin: 50px 0px;
  padding: 10px 0px;
  min-height: 280px;
}

@media (max-width: 576px) {
  .row.training-service {
    box-shadow: 2px 2px 9px 2px rgba(169, 169, 169, 0.35);
  }
}

.btn {
  background-color: #201b4a;
  color: white;
  border-radius: 10px;
  transition: all 0.3s;
}

.btn:hover {
  border-style: solid;
  border-color: #201b4a;
  color: #201b4a;
  background-color: white;
}
</style>
