<template>
  <nav class="navbar navbar-light navbar-expand-md">
    <div class="container">
      <router-link class="navbar-brand" to="/home"><img src="../../assets/img/img/CertifiedNet.png" class="img-certified img-fluid"></router-link
      ><button
        data-toggle="collapse"
        class="navbar-toggler"
        data-target="#navcol-1"
      >
        <span class="sr-only">Toggle navigation</span
        ><span class="navbar-toggler-icon"></span>
      </button>
      <div class="collapse navbar-collapse justify-content-end" id="navcol-1">
        <ul class="navbar-nav">
          <li class="nav-item"><router-link class="nav-link" to="/home">Inicio</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/training">Training</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/pearson-vue">Pearson Vue</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/about">Nosotros</router-link></li>
          <li class="nav-item"><router-link class="nav-link" to="/contact">Contacto</router-link></li>
        </ul>
      </div>
    </div>
  </nav>
</template>

<style scoped>
.navbar-brand {
  font-weight: 600;
  font-size: 1.5rem;
}

.navbar.navbar-light.navbar-expand-md {
  font-size: 1.1rem;
}

.img-certified {
  width: 5rem;
}
</style>